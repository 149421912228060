import {domReady} from '@roots/sage/client';
import AOS from 'aos';

domReady(() => {
  // Gebruik de window.onload event om te wachten totdat de volledige pagina geladen is
  window.onload = () => {
    // Initialiseer AOS
    AOS.init();
  };
});

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
  jQuery(document).ready(function () {
    jQuery('img').each(function () {
      var img_src = jQuery(this).attr('src');
      if (typeof img_src !== typeof undefined && img_src !== false) {
        var img_alt = jQuery(this).attr('alt');
        var str = img_src
        var pieces = str.split('/');
        var imgName = pieces[pieces.length - 1];
        var imgnameArray = imgName.split('.');
        var alt = imgnameArray[0];
        if (img_alt == '' || typeof img_alt === typeof undefined || img_alt === false) {
          jQuery(this).attr('alt', alt);
        }
      }
    });
  });

};
jQuery(document).ready(function () {
  jQuery('img').each(function () {
    var img = jQuery(this);
    var img_src = img.attr('src');
    if (!img.hasClass('lazyload')) {
      img.addClass('lazyload');
      img.attr('data-src', img_src);
    }
  });

  // Voeg een event listener toe om afbeeldingen te laden wanneer ze in het viewport verschijnen
  jQuery(window).on('scroll', function () {
    jQuery('img.lazyload').each(function () {
      var img = jQuery(this);
      if (isInViewport(img) && !img.hasClass('loaded')) {
        lazyloadImage(img);
      }
    });
  });
});

// Functie om een afbeelding te laden
function lazyloadImage(img) {
  var img_src = img.attr('data-src');
  if (typeof img_src !== typeof undefined && img_src !== false) {
    img.attr('src', img_src);
    img.removeClass('lazyload');
    img.addClass('loaded');
  }
}

// Functie om te controleren of een element in het viewport is
function isInViewport(element) {
  var rect = element[0].getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}





/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);